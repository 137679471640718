import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigatorService } from './modules/core/services/navigator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private previousPath: string = '';
  name = '';
  path = '';
  subitem = '';
  constructor(public navigator: NavigatorService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit() {
    this.navigator.event.subscribe(event => {
      setTimeout(() => {
        this.name = event.name;
        this.subitem = event.subitem;

        this.previousPath = event.path;
        this.path = event.path;
      }, 1);
    });
  }
}
