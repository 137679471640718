import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {


  @Output() event = new EventEmitter();
  constructor() { }

  changeNavigator(name, path) {

    let array = name.split('|');
    let subitem = '';

    if(array.length > 1) {
      name = array[0] + '|';
      subitem = array[1];
    }

    this.event.emit({name, path, subitem});
  }
}
